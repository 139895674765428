import React from "react";
import "./App.css";
import Contador from "./Components/Contador";

function App() {
  const fechas_nacimiento = {
    yo: { name: "Carlos", fecha: "05/06/1977" },
    pilar: { name: "Pilar", fecha: "10/01/1979" },
    gala: { name: "Gala", fecha: "10/10/2019" },
    carlitos: { name: "Carlitos", fecha: "22/04/2021" },
    morita: { name: "Morita", fecha: "22/06/2017" },
  };

  const fechas_eventos = {
    resu: { name: "Resurrection Fest:", fecha: "26/06/2024" },
  };

  return (
    <div className="App">
      Tempus V0.0.1 <br></br>.: Edades :.
      {Object.keys(fechas_nacimiento).map((nombre) => (
        <Contador
          key={nombre}
          nombre={fechas_nacimiento[nombre].name}
          fecha={fechas_nacimiento[nombre].fecha}
        />
      ))}
      .: Eventos :.
      {Object.keys(fechas_eventos).map((nombre) => (
        <Contador
          key={nombre}
          nombre={fechas_eventos[nombre].name}
          fecha={fechas_eventos[nombre].fecha}
        />
      ))}
    </div>
  );
}

export default App;
